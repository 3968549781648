



























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Panel from "@/components/Panel.vue";
import TimePicker from "@/components/TimePicker.vue";
import type { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import DaysOfWeek from "@/constants/DaysOfWeek";
import { conformToMask } from "text-mask-core";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";
import { WORK_HOURS } from "@/constants/WorkHours";

@Component({
  components: {
    Panel,
    TimePicker,
  },
})
export default class WorkHours extends Vue {
  public name = "WorkHours";

  public workHours = [];

  @Prop()
  public data!: any;

  @Prop({ default: () => false })
  public readonly!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public disabled!: boolean;

  public getWeekday(index: number): any {
    return DaysOfWeek[index - 1];
  }

  public buildDaysOfWeek: Array<IDayWorkHour> = [];
  public timeRangeActive: Array<any> = [];
  public currentRow: Array<any> | null = [];

  public getLabels(): Array<any> {

    if(this.readonly) {
      return [
        {label: this.$t("general.day"), key: "day"},
        {label: this.$t("general.from"), key: "startTime"},
        {label: this.$t("general.to"), key: "endTime"},
        {label: "", key: "warnings"}
      ];
    }
    else {
      return [
        {label: this.$t("general.day"), key: "day"},
        {label: this.$t("general.from"), key: "startTime"},
        {label: this.$t("general.to"), key: "endTime"},
        {label: this.$t("general.open"), key: "actions"},
        {label: "", key: "warnings"},
      ];
    }

  }

  public buildDayWorkHours(item: Array<IDayWorkHour> = []): void {
    DaysOfWeek.forEach((day: TranslateResult, index: number) => {
      const dayIndex = index + 1;
      let found: IDayWorkHour | undefined = item.find((x: IDayWorkHour) => x.dayOfWeek == dayIndex);

      if (found) {
        found.startTime = !found?.startTime ? WORK_HOURS.startTime : found.startTime;
        found.endTime = !found?.endTime ? WORK_HOURS.endTime : found.endTime;

        this.buildDaysOfWeek.push(found);
      } else {
        this.buildDaysOfWeek.push({
          dayOfWeek: dayIndex,
          startTime: WORK_HOURS.startTime,
          endTime: WORK_HOURS.endTime,
          active: true,
        });
      }
    });
  }

  @Watch("data", { immediate: true })
  public workHoursItemsWatcher(val: Array<IDayWorkHour>): void {
    if (this.readonly) {
      this.buildDaysOfWeek = val;
    } else {
      this.buildDaysOfWeek = [];
      this.buildDayWorkHours(val);
    }
    this.currentRow = this.buildDaysOfWeek.map((i: any) => i.active);
  }

  @Watch("buildDaysOfWeek", { deep: true })
  public buildDaysOfWeekWatcher(val: Array<IDayWorkHour>): void {
    this.$emit("built-days-of-week", val);
  }

  public isChecked(row: IDayWorkHour, index: number): boolean {
    return row?.active || true;
    // return Boolean(row.endTime && row.startTime);
  }

  public maskText(val: string): string {
    const hours = [/[0-2]/, val.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
    const minutes = [/[0-5]/, /[0-9]/];
    const mask = val.length > 2 ? [...hours, ":", ...minutes] : hours;
    const { conformedValue } = conformToMask(val, mask, { guide: false });
    return conformedValue;
  }

  public hourComparator(startTime: any, endTime: any) {
    if (startTime && endTime) {
      let str1 = startTime.split(":");
      let str2 = endTime.split(":");

      const totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
      const totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0]);

      return totalSeconds1 >= totalSeconds2;
    }
  }
}
